/**
 * This util help registering event listener
 * in the script tag of an Astro template.
 *
 * https://stackoverflow.com/a/77910937
 */
export function registerListenerWithViewTransition(listener: () => void) {
  listener();

  document.addEventListener("astro:after-swap", () => {
    listener();
  });
}
